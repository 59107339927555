<template>
    <div class="brand product">
        <el-container class="brand-main">
            <el-aside width="240px">
                <div class="filter-title">
                    <span>{{ $t('shai-xuan-tiao-jian') }}</span>
                    <el-button type="text" @click="refreash">{{ $t('zhong-zhi-shai-xuan-tiao-jian') }}</el-button>
                </div>
                <!-- <CountryTree v-model="chooseCountry" :continent.sync="continent"></CountryTree>

                <ProductBrandTree v-model="brand"></ProductBrandTree> -->

                <ProductCategoryTree
                    :chooseNode="chooseClassify"
                    v-model="chooseCategorys"
                    :defaultValue="defaultCategory"
                ></ProductCategoryTree>
                <ProductTagsTree v-model="chooseTags" :defaultValue="defaultTags"></ProductTagsTree>

                <ApplicationFieldTree
                    v-model="chooseApplicationFields"
                    :defaultValue="defaultApplicationField"
                ></ApplicationFieldTree>
            </el-aside>
            <el-container class="filter-show">
                <el-header height="56px" class="listTop">
                    <radio-group :list="sortList" v-model="sort"></radio-group>
                    <!-- <el-radio-group v-model="sort" class="sort">
                        <el-radio-button :label="item.key" v-for="item in sortList">
                            {{ $i18n.t(item.name) }}
                        </el-radio-button>
                    </el-radio-group> -->
                    <el-input
                        size="mini"
                        :placeholder="$t('zai-jie-guo-zhong-sou-suo')"
                        class="search"
                        v-model="searchKey"
                        clearable
                        @change="submitSearch"
                    >
                        <div slot="append" @click="submitSearch" class="iconfont">&#xe603;</div>
                    </el-input>

                    <span class="result"
                        >{{ $t('dang-qian-jie-guo-gong') }}{{ totalElements }}{{ $t('ge-shang-pin') }}</span
                    >

                    <el-radio-group v-model="showType" class="show">
                        <el-radio-button :label="item.value" v-for="item in listTypes" :key="item.value">
                            <div class="btn-info">
                                <span class="iconfont" v-html="item.icon"></span>
                                <span>{{ $i18n.t(item.label) }}</span>
                            </div>
                        </el-radio-button>
                    </el-radio-group>
                </el-header>

                <el-main v-loading="loading">
                    <div class="mainList" ref="list">
                        <el-row :gutter="30" v-if="showType == 'gride'" type="flex">
                            <el-col :xl="4" :lg="6" :md="8" :sm="8" :xs="8" v-for="item in list" :key="item.id">
                                <product-gride :info="item" :isNew="false"></product-gride>
                            </el-col>
                        </el-row>
                        <template v-else>
                            <div class="row" v-for="item in list" :key="item.id">
                                <product-row :info="item" :isNew="false" />
                            </div>
                        </template>
                    </div>
                    <empty-page v-if="empty"></empty-page>
                </el-main>
                <el-footer>
                    <el-pagination
                        @size-change="onSizeChange"
                        @current-change="onCurrentChange"
                        :current-page="page"
                        :page-sizes="[10, 20, 30, 40, 50]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalElements"
                    >
                    </el-pagination>
                </el-footer>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ProductGride from '../../components/product/Gride';
import ProductRow from '../../components/product/Row';
import Classify from '../../components/product/Classify';
import { getBanner } from '../../utils/CommonApi';
import pageableList from '../../mixins/pageableList';
import ListFilter from '../../components/ListFilter';
import { ProductSortType } from '../../utils/AppState';
import CountryTree from '../../components/tree/Countrys';
import ProductCategoryTree from '../../components/tree/ProductCategory';
import ApplicationFieldTree from '../../components/tree/ApplicationField';
import ProductTagsTree from '../../components/tree/ProductTags';
import ProductBrandTree from '../../components/tree/ProductBrand.vue';

export default {
    name: 'Product',
    mixins: [pageableList],
    data() {
        return {
            productCategorys: [],
            productTags: [],
            applicationFields: [],
            chooseClassify: '',
            chooseCategorys: '',
            chooseTags: '',
            chooseApplicationFields: '',
            chooseCountry: '',
            start: true,
            defaultCategory: '',
            url: '/product/show',
            sortList: [...ProductSortType].map(item => {
                return {
                    key: item[0],
                    name: item[1]
                };
            }),
            vendorInfoId: '',
            customCateId: '',
            brand: '',
            continent: ''
        };
    },
    props: {
        vendorInfo: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        ...mapState(['userInfo']),
        categoryProps() {
            return {
                children: 'children',
                label: this.$i18n.locale.indexOf('zh') !== -1 ? 'chName' : 'enName'
            };
        },
        listQuery() {
            return {
                categoryIds: this.chooseCategorys,
                tagIds: this.chooseTags,
                applicationField: this.chooseApplicationFields,
                productSort: this.sort,
                customCateId: this.customCateId,
                query: {
                    vendorInfoId: this.vendorInfoId
                },
                continent: this.continent,
                brand: this.brand
            };
        },
        routeQuery() {
            return {
                vendorInfoId: this.vendorInfoId
            };
        },
        defaultTags() {
            return this.$route.query.tagIds || '';
        },
        defaultApplicationField() {
            return (this.$route.query.applicationField || '').toString();
        }
    },
    watch: {
        listQuery() {
            if (!this.start) {
                this.page = 1;
                this.getData();
            }
        },
        vendorInfo() {
            if (this.vendorInfo.id) {
                this.getFiliterInfo();
            }
        }
    },
    components: {
        Swiper,
        SwiperSlide,
        ProductGride,
        ProductRow,
        Classify,
        ListFilter,
        CountryTree,
        ProductCategoryTree,
        ApplicationFieldTree,
        ProductTagsTree,
        ProductBrandTree
    },
    watch: {
        $route() {
            if (this.$route.query.customCateId) {
                this.search = '';
                this.customCateId = this.$route.query.customCateId;
                this.page = 1;
                this.getData();
            } else {
                this.search = '';
                this.page = 1;
                this.customCateId = '';
                this.getData();
            }
        },
        listQuery() {
            if (!this.start) this.getData();
        }
    },
    mounted() {
        this.loading = true;
        console.log(this.$route.query.categoryIds);
        if (this.$route.query.categoryIds) {
            this.chooseCategorys = this.$route.query.categoryIds.toString();
            this.defaultCategory = this.$route.query.categoryIds.toString();
        }
        if (this.$route.query.applicationField) {
            this.chooseApplicationFields = this.$route.query.applicationField.toString();
        }
        if (this.$route.query.customCateId) {
            this.customCateId = this.$route.query.customCateId;
        } else {
            this.customCateId = '';
        }
        if (this.$route.query.vendorInfoId) {
            this.vendorInfoId = this.$route.query.vendorInfoId;
            this.$emit('setStore', this.vendorInfoId);
            this.start = false;
            this.getData();
        }
    },
    methods: {
        getFiliterInfo() {
            this.productCategorys = this.vendorInfo.categories;
            // this.chooseClassify = this.$route.query.chooseClassify || '';
            this.applicationFields = this.vendorInfo.applications;
            this.$http.get('/productTag/allList').then(res => {
                this.productTags = res;
                this.$nextTick(() => {
                    this.getData();
                    this.start = false;
                });
            });
            // Promise.allSettled([
            //     this.$http.get('/productCategory/allList'),
            //     this.$http.get('/productTag/allList')
            // ]).then(res => {
            //     res.forEach((item, index) => {
            //         if (item.status === 'fulfilled') {
            //             if (index === 0) {
            //                 //分类
            //                 this.productCategorys = item.value;
            //                 this.chooseClassify =
            //                     this.$route.query.chooseClassify ||
            //                     this.productCategorys[0].id;
            //             } else if (index === 1) {
            //                 //标签
            //                 this.productTags = item.value;

            //                 //应用领域标签的42
            //                 this.applicationFields = item.value
            //                     .filter(item => {
            //                         return item.id === 42;
            //                     })
            //                     .map(item => {
            //                         return item.children;
            //                     })
            //                     .flat();
            //
            //             }
            //         }
            //     });
            // });
        },
        changeClassify(id) {
            this.defaultCategory = '';
            this.chooseClassify = id;
        },
        refreash() {
            this.defaultCategory = '';
            this.chooseCategorys = '';
            this.chooseTags = '';
            this.chooseApplicationFields = '';
            this.chooseCountry = '';
            this.continent = '';
            this.brand = '';
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/pagebleTable';

.classifies {
    display: flex;
    max-width: 1920px - 160px * 2;
    padding: 30px $--page-padding;
    margin: 0 auto;

    .classify {
        flex-grow: 1;
        margin: 0 5px;
    }
}

.product {
    background-color: #f5f7fa;
}
</style>
